<template>
  <div class="jcontactview">
    <div class="jcontactview__jumbotron">
      <div class="jcontactview__jumbotron-content">
        <div class="jcontactview__desc-content">
          <h1 class="jcontactview__title">
            Contact Us for Detail
          </h1>
          <p class="jcontactview__desc">
            Get in touch with us today! Whether you have questions, need assistance, or want <br/>to explore potential partnerships, our team is here to help. Reach out through the <br/>contact form below or use the details provided to connect directly.
          </p>
        </div>
      </div>
    </div>
    <div class="jcontactview__contact-container">
      <div class="jcontactview__title-desc-container">
        <h3 class="jcontactview__subtitle jcontactview__subtitle--black">
          Leave the <span class="jcontactview__subtitle jcontactview__subtitle--blue">message or comment</span>
        </h3>
        <p class="jcontactview__desc--black">
          Write to us, and we will make every effort to respond as quickly as possible.
        </p>
      </div>
      <div class="jcontactview__contact-content">
        <div class="jcontactview__left-content">
          <JEmailForm></JEmailForm>
        </div>
        <div class="jcontactview__right-content">
          <div class="jfooter__right-content">
            <div class="jfooter__subtitle">Company</div>
            <div class="jfooter__desc">Jalan Boulevar Selatan 5 Ruko Sinpasa Blok SB 27 Sumarecon Bekasi 17142</div>
            <div class="jfooter__wrapper-desc">
              <img src="@/assets/icon-phone.svg" alt="call">
              <div class="jfooter__desc">+62 813 1931 7725</div>
            </div>
            <div class="jfooter__wrapper-desc">
              <img src="@/assets/icon-mail.svg" alt="call">
              <div class="jfooter__desc-container">
                <div class="jfooter__desc">cattelia@joullineoutstandingpartnership.com</div>
                <div class="jfooter__desc">joullineoutstandingpartnership@gmail.com</div>
                <div class="jfooter__desc">jop.partnership@gmail.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jcontactview__story-container">
      <div class="jcontactview__story-content">
        <div class="jcontactview__title-desc-container">
          <h3 class="jcontactview__subtitle jcontactview__subtitle--black">
            Our<span class="jcontactview__subtitle jcontactview__subtitle--blue"> stories</span>
          </h3>
          <p class="jcontactview__desc--black">
            Our valued partners have shared their positive experiences, highlighting the impactful collaboration and outstanding results achieved through our teamwork.
          </p>
        </div>
        <div class="jcontactview__image-container">
          <div 
            v-for="(image, index) in images" 
            :key="index" 
            class="jcontactview__story-image">
            <img :src="image.link" :alt="image.alt">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pd1 from '../assets/about-gallery/ab-1.png'
  import pd2 from '../assets/about-gallery/ab-2.png'
  import pd3 from '../assets/about-gallery/ab-3.png'
  import pd4 from '../assets/about-gallery/ab-4.png'
  import pd5 from '../assets/about-gallery/ab-5.png'
  import pd6 from '../assets/about-gallery/ab-6.png'
  import JEmailForm from '@/components/EmailForm.vue'

  export default {
    name: 'ContactView',
    components: {
      JEmailForm
    },
    data() {
      return {
        images: [
          { link: pd1, alt: 'Description of image 1' },
          { link: pd2, alt: 'Description of image 2' },
          { link: pd3, alt: 'Description of image 3' },
          { link: pd4, alt: 'Description of image 4' },
          { link: pd5, alt: 'Description of image 5' },
          { link: pd6, alt: 'Description of image 6' },
        ]
      }
    }
  }
</script>

<style scoped lang="scss">

  .jcontactview {
    &__title {
      margin: 0;
      font-size: 78px;
      font-weight: bold;
      color: #FFFF;
      @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
      }
    }
    &__subtitle {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
      color: #FFFF;
      &--blue { 
        color: #3397D2;
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      &--black { 
        color: #1E1E1E;
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
      }
    }
    &__contact-container {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
    }
    &__desc {
      margin: 0;
      color: #FFFF;
      font-size: 24px;
      line-height: 36px;
      &--black {
        color: #1E1E1E;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 20px;
          text-align: center;
          line-height: normal;
        }
      }
      @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        line-height: normal;
      }
    }
    &__title-desc-container {
      display: flex;
      gap: 24px;
      flex-direction: column;
      text-align: center;
    }
    &__contact-content {
      display: flex;
      width: 1200px;
      margin: 50px auto;
      gap: 40px;
    }
    &__left-content {
      flex: 2;
    }
    &__right-content {
      flex: 1;
    }
    &__jumbotron {
      background: url('../assets/jumbotron-background.png');
      background-size: cover;
    }
    &__jumbotron-content {
      display: flex;
      max-width: 1520px;
      margin: auto;
      gap: 70px;
      align-items: center;
      justify-content: center;
      padding: 100px 24px 245px 24px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 50px 24px 80px 24px;
      }
    }
    &__desc-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
    }
    &__story-container {
      background-color: #3398d215;
    }
    &__story-content {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
    &__image-container {
      display: flex;
      gap: 16px;
      max-width: 1232px;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      @media (max-width: 768px) {
        justify-content: center;
      }
    }
    &__story-image {
      width: 400px;
      height: 300px;
      border-radius: 16px;
      background-color: #D9D9D9;
      img {
        width: 400px;
        height: 300px;
      }
      @media (max-width: 768px) {
        width: 300px;
        height: 200px;
        img {
          width: 300px;
          height: 200px;
        }
      }
    }
    @media (max-width: 768px) {
      &__contact-content {
        width: 85%;
        padding: 0px 16px;
        flex-direction: column;
      }
    }
  }

  .jfooter {
    &__right-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;
    }
    &__subtitle {
      font-size: 18px;
      font-weight: 600;
      color: #3397D2;
    }
    &__desc {
      font-size: 14px;
      line-height: 123.5%;
    }
    &__desc-container {
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
    &__wrapper-desc {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }
</style>
