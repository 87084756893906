<template>
  <div class="jmail">
    <form class="jmail__content-wrapper" @submit.prevent="redirectToEmail">
      <div class="jmail__input-wrapper">
        <label for="name">Name:</label>
        <input type="text" v-model="formData.name" id="name" required />
      </div>
      <div class="jmail__input-wrapper">
        <label for="userEmail">Email:</label>
        <input type="email" v-model="formData.userEmail" id="userEmail" required />
      </div>
      <div class="jmail__input-wrapper">
        <label for="comment">Message:</label>
        <textarea v-model="formData.comment" id="comment" maxlength="1000" required></textarea>
      </div>
      <button class="jmail__button" type="submit">Send</button>
    </form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formData: {
          name: '',
          userEmail: '',
          comment: ''
        }
      }
    },
    methods: {
      redirectToEmail() {
        const { name, userEmail, comment } = this.formData

        const mailtoLink = `mailto:abdidarsono11@gmail.com?subject=Message from ${encodeURIComponent(
          name
        )}&body=${encodeURIComponent(
          `Name: ${name}\nEmail: ${userEmail}\nMessage:\n${comment}`
        )}`
        window.location.href = mailtoLink
      }
    }
  }
</script>

<style scoped lang="scss">
.jmail {
  width: 100%;
  margin: 0 auto;
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    label {
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      margin: 0;
      padding: 0;
    }
    input,
    textarea {
      width: 100%;
      height: 80px;
      padding: 8px 12px;
      margin: 8px 0;
      box-sizing: border-box;
      outline: none;
      border: 1px solid #BDBDBD;
      border-radius: 6px;
      font-size: 14px;
    }
    textarea {
      height: 100px;
      padding: 12px;
    }
  }
  &__button {
    align-self: flex-end;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    text-align: center;
    background-color: #3397D2;
    color: whitesmoke;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #1a5374;
    }
  }

  @media (max-width: 768px) {
    width: 85%;
    &__input-wrapper {
      flex-direction: column;
      align-items: flex-start;
      label {
        width: 100%;
        font-size: 0.8rem;
      }
      input {
        height: 40px;
      }
    }
    .jcontactview__contact-wrapper {
      width: 80%;
    }
    &__button {
      width: 100%;
    }
  }
}
</style>
