<template>
  <div class="jbanner">
    <h1 class="jbanner__title">
      <span class="jbanner__title--black">Delivering customer service</span> that goes<br>one step further in satisfaction.
    </h1>
    <p class="jbanner__desc">
      Our company is dedicated to delivering exceptional quality and innovative solutions<br>that set us apart, ensuring you receive the best service possible.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
	.jbanner {
		padding: 120px 0px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		gap: 24px;
		background-image: url('../assets/banner-background.png');
		background-size: cover;
		@media (max-width: 768px) {
			padding: 24px;
		}
		&__title {
			font-size: 48px;
			font-weight: bold;
			color: #FFF;
			margin: 0;
			&--black {
				color: #1E1E1E;
			}
			@media (max-width: 768px) {
        font-size: 30px;
      }
		}
		&__desc {
			color: #FFF;
			font-size: 24px;
			font-weight: medium;
			line-height: 36px;
			margin: 0;
			@media (max-width: 768px) {
        font-size: 18px;
				line-height: normal;
      }
		}
	}

</style>