<template>
  <div class="jaboutview">
    <div class="jaboutview__jumbotron">
      <div class="jaboutview__jumbotron-content">
        <div class="jaboutview__desc-content">
          <h1 class="jaboutview__title">
            Empowering Innovation <br>and Excellence
          </h1>
          <p class="jaboutview__desc">
            Founded in 2022, We have been working together with our clients from various <br>industries in aligning their people and processes to compete through high speed, <br>excellent quality and low cost.
          </p>
        </div>
      </div>
    </div>
    <div class="jaboutview__vision-container">
      <div class="jaboutview__card-container">
        <div 
          v-for="card in cards"
          :key="card.title"
          class="jcard">
          <div class="jcard__image">
            <img :src="card.image" :alt="card.title">
            <h4 class="jcard__title jcard__title--black">{{ card.title }}</h4>
          </div>
          <div class="jcard__content">
            <!-- Loop through subtitles -->
            <div 
              v-for="(subtitle, index) in card.subtitle" 
              :key="index">
              <h5 class="jcard__subtitle">{{ subtitle.name }}</h5>
              <p class="jcard__desc">{{ subtitle.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jaboutview__team-container">
      <div class="jaboutview__title-desc-container">
        <h3 class="jaboutview__subtitle jaboutview__subtitle--black">
          Our <span class="jaboutview__subtitle jaboutview__subtitle--blue">amazing-teams</span>
        </h3>
        <p class="jaboutview__desc--black">
          Our team is a diverse group of skilled professionals, united by a shared commitment to innovation, collaboration, and delivering exceptional results. Together, we bring a wealth of expertise and passion to every project, driving our company’s success and creating lasting value for our clients.
        </p>
      </div>
      <div class="jaboutview__user-card-container">
        <div class="jcard-variant"
          v-for="card in cardVariant" :key="card">
          <div class="jcard-variant__image">
            <img :src="card.image" :alt="card.image">
          </div>
          <div class="jcard-variant__content">
            <h3 class="jcard-variant__title">
              {{ card.title }}
            </h3>
            <p class="jcard-variant__desc">
              {{ card.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Jbanner></Jbanner>
    <div class="jaboutview__story-container">
      <div class="jaboutview__story-content">
        <div class="jaboutview__title-desc-container">
          <h3 class="jaboutview__subtitle jaboutview__subtitle--black">
            Our<span class="jaboutview__subtitle jaboutview__subtitle--blue"> stories</span>
          </h3>
          <p class="jaboutview__desc--black">
            Our valued partners have shared their positive experiences, highlighting the impactful collaboration and outstanding results achieved through our teamwork.
          </p>
        </div>
        <div class="jaboutview__image-container">
          <div 
            v-for="(image, index) in images" 
            :key="index" 
            class="jaboutview__story-image"
          >
            <img :src="image.link" :alt="image.alt" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import imageVision1 from '../assets/icon-vision-1.png'
  import imageVision2 from '../assets/icon-vision-2.png'
  import imageVision3 from '../assets/icon-vision-3.png'
  import user1 from '../assets/organizational-structure/user-1.png'
  import user2 from '../assets/organizational-structure/user-2.png'
  import user3 from '../assets/organizational-structure/user-3.png'
  import user4 from '../assets/organizational-structure/user-4.png'
  import user5 from '../assets/organizational-structure/user-5.png'
  import pd1 from '../assets/about-gallery/ab-1.png'
  import pd2 from '../assets/about-gallery/ab-2.png'
  import pd3 from '../assets/about-gallery/ab-3.png'
  import pd4 from '../assets/about-gallery/ab-4.png'
  import pd5 from '../assets/about-gallery/ab-5.png'
  import pd6 from '../assets/about-gallery/ab-6.png'
  import Jbanner from '../components/BannerVariant.vue'

  export default {
    name: 'AboutView',
    components: {
      Jbanner
    },
    data() {
      return {
        cards: [
          {
            image: imageVision1,
            title: 'Our vision',
            subtitle: [
              { desc: 'To be known as a leading consulting company in making Productivity and Quality Improvement through people works' }
            ],
          },
          {
            image: imageVision2,
            title: 'Our mission',
            subtitle: [
              { name: 'Helping', desc: 'Helping client’s company to get better quality and productivity.' },
              { name: 'Contribute', desc: 'Contribute to our stakeholders and clients reach their utmost potentials.' },
              { name: 'Provide', desc: 'Provide the society for better quality of life.' }
            ],
          },
          {
            image: imageVision3,
            title: 'Our value',
            subtitle: [
              { name: 'Customer Basis', desc: 'Customers are our main reason we are here and doing our best.' },
              { name: 'Integrity and Ethical Conduct', desc: 'Partnership, teamwork, continuous learning, and empowerment professional, open and long-term partnership.' },
              { name: 'Entrepreneurship Value', desc: 'Innovative, creative, and problem solver.' },
            ],
          },
        ],
        cardVariant: [
          {
            image: user3,
            title: 'Sisil',
            desc: 'HRD at Recruitment and Travel Division'
          },
          {
            image: user1,
            title: 'Gio',
            desc: 'Legal Manager'
          },
          {
            image: user2,
            title: 'Catelia',
            desc: 'Founder and CEO'
          },
          {
            image: user5,
            title: 'Bayu',
            desc: 'Manager at Business Sales and Supplier Division'
          },
          {
            image: user4,
            title: 'Rangga',
            desc: 'Manager at Business Sales and Logistic Division'
          }
        ],
        images: [
          { link: pd1, alt: 'Description of image 1' },
          { link: pd2, alt: 'Description of image 2' },
          { link: pd3, alt: 'Description of image 3' },
          { link: pd4, alt: 'Description of image 4' },
          { link: pd5, alt: 'Description of image 5' },
          { link: pd6, alt: 'Description of image 6' },
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  .jaboutview {
    &__title {
      margin: 0;
      font-size: 78px;
      font-weight: bold;
      color: #FFFF;
      @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
      }
    }
    &__desc {
      margin: 0;
      color: #FFFF;
      font-size: 24px;
      line-height: 36px;
      &--black {
        color: #1E1E1E;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 20px;
          text-align: center;
          line-height: normal;
        }
      }
      @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        line-height: normal;
      }
    }
    &__subtitle {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
      color: #FFFF;
      &--blue { 
        color: #3397D2; 
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      &--black { 
        color: #1E1E1E;
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
      }
    }
    &__title-desc-container {
      display: flex;
      gap: 24px;
      flex-direction: column;
      text-align: center;
    }
    &__jumbotron {
      background: url('../assets/jumbotron-background.png');
      background-size: cover;
    }
    &__jumbotron-content {
      display: flex;
      max-width: 1520px;
      margin: auto;
      gap: 70px;
      align-items: center;
      justify-content: center;
      padding: 100px 24px 245px 24px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 50px 24px 80px 24px;
      }
    }
    &__desc-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
    }
    &__vision-container {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
    }
    &__card-container {
      display: grid;
      grid-template-columns: repeat(3, 484px);
      gap: 32px;
      justify-content: center;
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        gap: 16px;
      }
      @media (max-width: 480px) {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 16px; /* Consistent gaps */
      }
    }
    &__team-container {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
    &__user-card-container {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;
      justify-content: center;
      @media (max-width: 768px) {
        justify-content: center;
      }
    }
    &__story-container {
      background-color: #3398d215;
    }
    &__story-content {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
    &__image-container {
      display: flex;
      gap: 16px;
      max-width: 1232px;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      @media (max-width: 768px) {
        justify-content: center
      }
    }
    &__story-image {
      width: 400px;
      height: 300px;
      border-radius: 16px;
      background-color: #D9D9D9;
      img {
        width: 400px;
        height: 300px;
      }
      @media (max-width: 768px) {
        width: 300px;
        height: 200px;
        img {
          width: 300px;
          height: 200px;
        }
      }
    }
  }

  .jcard {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    gap: 64px;
    height: 700px;
    @media (max-width: 768px) {
      height: auto;
    }
    &:nth-child(2) {
      margin-top: 100px;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    &:nth-child(3) {
      margin-top: 200px;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    &__image {
      display: flex;
      flex-direction: column;
      gap: 16px;
      img {
        width: 64px;
      }
    }
    &__title {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
      color: #FFFF;
      &--blue { 
        color: #3397D2; 
        @media (max-width: 768px) {
          font-size: 35px;
        }
      }
      &--black { 
        color: #1E1E1E;
        @media (max-width: 768px) {
          font-size: 35px;
        }
      }
      @media (max-width: 768px) {
        font-size: 35px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        gap: 12px;
      }
    }
    &__subtitle {
      font-size: 24px;
      line-height: 36px;
      color: #3397D2;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: normal;
      }
    }
    &__desc {
      font-size: 24px;
      line-height: 36px;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  .jcard-variant {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 277px;
    text-align: cen;
    &__content {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    &__image {
      width: 277px;
      height: 277px;
      border-radius: 50%;
      img {
        width: 277px;
        height: 277px;
      }
    }
    &__title {
      font-size: 36px;
      line-height: 36px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: normal;
      }
    }
    &__desc {
      font-size: 24px;
      line-height: 36px;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

</style>
