<template>
  <footer class="jfooter">
    <div class="jfooter__container-content">
      <div class="jfooter__left-content">
        <img class="jfooter__logo" src="@/assets/joulline-logo-2.png" alt="second logo">
        <div class="jfooter__desc">© 2024 Joulline Outstanding Partnership.</div>
      </div>
      <div class="jfooter__right-content">
        <div class="jfooter__subtitle">Company</div>
        <div class="jfooter__desc">Jalan Boulevar Selatan 5 Ruko Sinpasa Blok SB 27 Sumarecon Bekasi 17142</div>
        <div class="jfooter__wrapper-desc">
          <img src="@/assets/icon-phone.svg" alt="call">
          <div class="jfooter__desc">+62 813 1931 7725</div>
        </div>
        <div class="jfooter__wrapper-desc">
          <img src="@/assets/icon-mail.svg" alt="call">
          <div class="jfooter__desc-container">
            <div class="jfooter__desc">cattelia@joullineoutstandingpartnership.com</div>
            <div class="jfooter__desc">joullineoutstandingpartnership@gmail.com</div>
            <div class="jfooter__desc">jop.partnership@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "JFooter",
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

  .jfooter {
    display: flex;
    margin: 120px auto;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      margin: 40px auto;
      padding: 0px 16px;
    }
    &__container-content {
      display: flex;
      gap: 32px;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
    &__left-content,
    &__right-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;
    }
    &__logo {
      width: 327px;
    }
    &__subtitle {
      font-size: 18px;
      font-weight: 600;
      color: #3397D2;
    }
    &__desc {
      font-size: 14px;
      line-height: 123.5%;
    }
    &__desc-container {
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
    &__wrapper-desc {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }

</style>
