<template>
  <div class="jhome__header-container">
    <JHeader class="jhome__header" :links="navLinks" />
  </div>
  <router-view />
  <div class="jhome__footer-app">
    <JFooter class="container" :links="footerLinks "/>
  </div>
  <JWAButton />
</template>

<script>
import JHeader from '@/components/Header.vue'
import JFooter from '@/components/Footer.vue'
import JWAButton from '@/components/CTAButton.vue'

export default {
  name: "App",
  components: {
    JHeader,
    JFooter,
    JWAButton
  },
  data() {
    return {
      navLinks: [
        { name: 'Home', href: '/' },
        { name: 'About', href: '/about' },
        { name: 'Photos', href: '/photos' },
        { name: 'Product', href: '/products' },
        { name: 'Contact', href: '/contact' }
        // { name: 'Housing', href: '/housing-type/district1',
        //   children: [ 
        //     { name: 'District 1', href: '/housing-type/district1' }
        //   ]
        // },
      ]
    }
  },
  computed: {
    isHomeRoute() {
      return this.$route.path === '/';
    }
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

  body {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p { margin: 0; }

  #app {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
  }
  .container { margin: 30px auto; }

  .jhome {
    &__header-container {
      height: 148px;
      margin: auto;
      background-color: white;
      @media (max-width: 768px) {
        height: 120px;
      }
    }
    &__header {
      margin: auto;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3397D2;
    }
  }

  @mixin mobile {
    @media screen and (max-width: (768px - 1px)) {
      @content;
    }
  }
</style>