<template>
  <div class="jphotosview">
    <div class="jphotosview__jumbotron">
      <div class="jphotosview__jumbotron-content">
        <div class="jphotosview__desc-content">
          <h1 class="jphotosview__title">
            Explore the moments <br>that define our journey
          </h1>
          <p class="jphotosview__desc">
            Through photos showcasing our team, workplace, and the impactful work we do together.
          </p>
        </div>
      </div>
    </div>
    <div class="jphotosview__story-container">
      <div class="jphotosview__story-content">
        <div class="jphotosview__title-desc-container">
          <h3 class="jphotosview__subtitle jphotosview__subtitle--black">
            Our <span class="jphotosview__subtitle jphotosview__subtitle--blue">documentation</span>
          </h3>
          <p class="jphotosview__desc--black">
            Our documentation section highlights the key activities, important meetings, and valuable client interactions that drive our projects forward. It reflects our commitment to transparency, collaboration, and delivering excellence in every partnership we cultivate.
          </p>
        </div>
        <div class="jphotosview__image-container">
          <div 
            v-for="(image, index) in images" 
            :key="index"
            class="jphotosview__story-image">
            <img :src="image.link" :alt="image.alt" />
          </div>
        </div>
      </div>
    </div>
    <Jbanner></Jbanner>
  </div>
</template>

<script>
  import pd1 from '../assets/documentation/doc-1.png'
  import pd2 from '../assets/documentation/doc-2.png'
  import pd3 from '../assets/documentation/doc-3.png'
  import pd4 from '../assets/documentation/doc-4.png'
  import pd5 from '../assets/documentation/doc-5.png'
  import pd6 from '../assets/documentation/doc-6.png'
  import pd7 from '../assets/documentation/doc-7.png'
  import pd8 from '../assets/documentation/doc-8.png'
  import pd9 from '../assets/documentation/doc-9.png'
  import Jbanner from '../components/BannerVariant.vue'

  export default {
    name: 'PhotosView',
    components: {
      Jbanner
    },
    data() {
      return {
        images: [
          { link: pd1, alt: 'Description of image 1' },
          { link: pd2, alt: 'Description of image 2' },
          { link: pd3, alt: 'Description of image 3' },
          { link: pd4, alt: 'Description of image 4' },
          { link: pd5, alt: 'Description of image 5' },
          { link: pd6, alt: 'Description of image 6' },
          { link: pd7, alt: 'Description of image 4' },
          { link: pd8, alt: 'Description of image 5' },
          { link: pd9, alt: 'Description of image 6' },
        ],
      }
    }
  }
</script>

<style scoped lang="scss">

  .jphotosview {
    &__title {
      margin: 0;
      font-size: 78px;
      font-weight: bold;
      color: #FFFF;
      @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
      }
    }
    &__desc {
      margin: 0;
      color: #FFFF;
      font-size: 24px;
      line-height: 36px;
      &--black {
        color: #1E1E1E;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 20px;
          text-align: center;
          line-height: normal;
        }
      }
      @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        line-height: normal;
      }
    }
    &__subtitle {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
      color: #FFFF;
      &--blue { 
        color: #3397D2; 
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      &--black { 
        color: #1E1E1E;
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
      }
    }
    &__title-desc-container {
      display: flex;
      gap: 24px;
      flex-direction: column;
      text-align: center;
    }
    &__jumbotron {
      background: url('../assets/jumbotron-background.png');
      background-size: cover;
    }
    &__jumbotron-content {
      display: flex;
      max-width: 1520px;
      margin: auto;
      gap: 70px;
      align-items: center;
      justify-content: center;
      padding: 100px 24px 245px 24px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 50px 24px 80px 24px;
      }
    }
    &__desc-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
    }
    &__story-container {
      background-color: #3398d215;
    }
    &__story-content {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
    &__image-container {
      display: flex;
      gap: 16px;
      max-width: 1232px;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      @media (max-width: 768px) {
        justify-content: center
      }
    }
    &__story-image {
      width: 400px;
      height: 300px;
      border-radius: 16px;
      background-color: #D9D9D9;
      img {
        width: 400px;
        height: 300px;
      }
      @media (max-width: 768px) {
        width: 300px;
        height: 200px;
        img {
          width: 300px;
          height: 200px;
        }
      }
    }
  }
</style>
