<template>
  <div class="jproductsview">
    <div class="jproductsview__jumbotron">
      <div class="jproductsview__jumbotron-content">
        <div class="jproductsview__desc-content">
          <h1 class="jproductsview__title">
            Our Range of Innovative <br>Products
          </h1>
          <p class="jproductsview__desc">
            Our products are crafted with precision and innovation to meet the evolving needs of our clients. Each offering is designed to deliver exceptional quality, reliability, and value, ensuring that we exceed expectations in every industry we serve.
          </p>
        </div>
      </div>
    </div>
    <div class="jproductsview__story-container">
      <div class="jproductsview__story-content">
        <div class="jproductsview__title-desc-container">
          <h3 class="jproductsview__subtitle jproductsview__subtitle--black">
            Our <span class="jproductsview__subtitle jproductsview__subtitle--blue">innovative-products</span>
          </h3>
          <p class="jproductsview__desc--black">
            Our innovative products are at the forefront of industry advancements, combining cutting-edge technology with thoughtful design to solve complex challenges and create meaningful value for our clients.
          </p>
        </div>
        <div class="jproductsview__image-container">
          <div v-for="product in products" :key="product" class="jproductsview__story-image">
            <div class="jproductsview__product-image-container">
              <img v-for="(image, index) in product.image" :key="index" :src="image.src" :alt="image.src">
            </div>
            <p class="jproductsview__product-desc">{{ product.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <Jbanner></Jbanner>
  </div>
</template>

<script>
import product1 from '../assets/products/pd-3.png'
import product2 from '../assets/products/pd-2.png'
import product3 from '../assets/products/pd-17.png'
import product4 from '../assets/products/pd-18.png'
import product5 from '../assets/products/pd-16.png'
import product6 from '../assets/products/pd-15.png'
import product7 from '../assets/products/pd-14.png'
import product8 from '../assets/products/pd-13.png'
import product9 from '../assets/products/pd-12.png'
import product10 from '../assets/products/pd-11.png'
import product11 from '../assets/products/pd-10.png'
import product12 from '../assets/products/pd-9.png'
import product13 from '../assets/products/pd-8.png'
import product14 from '../assets/products/pd-7.png'
import product15 from '../assets/products/pd-6.png'
import product16 from '../assets/products/pd-5.png'
import product17 from '../assets/products/pd-4.png'
import product19 from '../assets/products/pd-19.png'
import product20 from '../assets/products/pd-20.png'
import product21 from '../assets/products/pd-21.png'
import product22 from '../assets/products/pd-22.png'
import product23 from '../assets/products/pd-23.png'
import product24 from '../assets/products/pd-24.png'
import product25 from '../assets/products/pd-25.png'
import product26 from '../assets/products/pd-26.png'
import product27 from '../assets/products/pd-27.png'
import product28 from '../assets/products/pd-28.png'
import product29 from '../assets/products/pd-29.png'
import product30 from '../assets/products/pd-30.png'
import product31 from '../assets/products/pd-31.png'
import product32 from '../assets/products/pd-32.png'
import product35 from '../assets/products/pd-35.png'
import product36 from '../assets/products/pd-36.png'
import product37 from '../assets/products/pd-37.png'
import Jbanner from '../components/BannerVariant.vue'

export default {
  name: 'PhotosView',
  components: {
    Jbanner
  },
  data() {
    return {
      products: [
        {
          image: [
            {
              src: product1
            },
            {
              src: product2
            },
            {
              src: product3
            }
          ],
          desc: '5kg Premium Rice from Indonesian Farmers'
        },
        {
          image: [
            {
              src: product24
            },
            {
              src: product25
            }
          ],
          desc: 'Coffee from Indonesian Farmers Product'
        },
        {
          image: [
            {
              src: product4
            },
            {
              src: product5
            },
            {
              src: product6
            }
          ],
          desc: 'Popular Indonesian Snacks'
        },
        {
          image: [
            {
              src: product7
            },
            {
              src: product8
            },
            {
              src: product9
            }
          ],
          desc: 'Snacks from Indonesian Micro, Small, and Medium Enterprises'
        },
        {
          image: [
            {
              src: product26
            },
            {
              src: product27
            }
          ],
          desc: 'Brasmati Rice - Import'
        },
        {
          image: [
            {
              src: product28
            },
            {
              src: product29
            }
          ],
          desc: 'Cloves'
        },
        {
          image: [
            {
              src: product30
            },
            {
              src: product31
            },
            {
              src: product32
            }
          ],
          desc: 'Used Cooking Oil'
        },
        {
          image: [
            {
              src: product10
            },
            {
              src: product11
            },
          ],
          desc: 'Chicken & Buffalo'
        },
        {
          image: [
            {
              src: product12
            },
            {
              src: product13
            },
            {
              src: product14
            }
          ],
          desc: 'Indonesia Cigarettes'
        },
        {
          image: [
            {
              src: product15
            },
            {
              src: product16
            },
            {
              src: product17
            }
          ],
          desc: 'Staple Food from Indonesian Micro, Small, Medium Enterprises'
        },
        {
          image: [
            {
              src: product19
            },
            {
              src: product20
            }
          ],
          desc: 'Gold Trading'
        },
        {
          image: [
            {
              src: product21
            },
            {
              src: product22
            },
            {
              src: product23
            }
          ],
          desc: 'Coal and Mining'
        },
        {
          image: [
            {
              src: product35
            },
            {
              src: product36
            },
            {
              src: product37
            }
          ],
          desc: 'Bricket'
        }
      ]
    }
  },
}
</script>

<style scoped lang="scss">

  .jproductsview {
    &__title {
      margin: 0;
      font-size: 78px;
      font-weight: bold;
      color: #FFFF;
      @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
      }
    }
    &__desc {
      margin: 0;
      color: #FFFF;
      font-size: 24px;
      line-height: 36px;
      &--black {
        color: #1E1E1E;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 20px;
          text-align: center;
          line-height: normal;
        }
      }
      @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        line-height: normal;
      }
    }
    &__subtitle {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
      color: #FFFF;
      &--blue { 
        color: #3397D2; 
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      &--black { 
        color: #1E1E1E;
        @media (max-width: 768px) {
          font-size: 35px;
          text-align: center;
        }
      }
      @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
      }
    }
    &__title-desc-container {
      display: flex;
      gap: 24px;
      flex-direction: column;
      text-align: center;
    }
    &__jumbotron {
      background: url('../assets/jumbotron-background.png');
      background-size: cover;
    }
    &__jumbotron-content {
      display: flex;
      max-width: 1520px;
      margin: auto;
      gap: 70px;
      align-items: center;
      justify-content: center;
      padding: 100px 24px 245px 24px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 50px 24px 80px 24px;
      }
    }
    &__desc-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
    }
    &__story-container {
      background-color: #3398d215;
    }
    &__story-content {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
    &__image-container {
      display: flex;
      max-width: 1232px;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      flex-direction: column;
      gap: 100px;
      @media (max-width: 768px) {
        justify-content: center
      }
    }
    &__story-image {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
      img {
        width: 400px;
        border-radius: 16px;
        @media (max-width: 768px) {
          width: 200px;
        }
      }
    }
    &__product-image-container {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 400px;
      justify-content: center;
      border-radius: 16px;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 250px;
      }
    }
    &__product-desc {
      font-size: 36px;
      line-height: 36px;
      color: #1E1E1E;
      @media (max-width: 768px) {
        font-size: 24px;
        text-align: center;
      }
    }
  }
</style>
