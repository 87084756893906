<template>
  <nav class="jheader">
    <div class="jheader__content-wrapper">
      <router-link to="/" class="box jheader__logo">
        <img src="@/assets/joulline-logo.png" alt="logo" />
      </router-link>
      <div v-if="!isMobile" class="box jheader__router-wrapper">
        <router-link
          class="jheader__router-item-wrapper"
          v-for="(link, index) in links"
          :key="index"
          :to="link.href"
        >
          <div class="jheader__router-item">
            {{ link.name }}
          </div>
        </router-link>
      </div>
      <div v-else class="box jheader__hamburger" @click="toggleMobileMenu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#FFFFF"
        >
          <path
            d="M0-480v-60h960v60H0Zm0 300v-60h960v60H0Zm0-600v-60h960v60H0Z"
          />
        </svg>
      </div>
    </div>
    <div v-if="isMobile && isMobileMenuOpen" class="jheader__mobile-menu">
      <router-link
        class="jheader__router-item-wrapper"
        v-for="(link, index) in links"
        :key="index"
        :to="link.href"
        @click.native="closeMobileMenu"
      >
        <div class="jheader__router-item">
          {{ link.name }}
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "JHeader",
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isMobileMenuOpen: false,
      isMobile: false
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize)
  },
}
</script>

<style lang="scss">
.jheader {
  display: flex;
  align-items: center;
  z-index: 99;
  &__content-wrapper {
    background-color: #3397D2;
    width: 1520px;
    margin: 24px auto;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0 16px;
    }
  }
  &__router-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__router-item-wrapper {
    position: relative;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 24px;
    border-radius: 8px;
    &:hover {
      background-color: #FFFF;
    }
    &:hover .jheader__router-item {
      color: #3397D2;
    }
    @media (max-width: 768px) {
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__router-item {
    text-decoration: none;
    transition: .5s all ease;
    font-size: 16px;
    font-weight: 500;
    color: #FFFF;
    &:hover {
      color: #a92b1f;
    }
    @media (max-width: 768px) {
      font-size: .9rem;
    }
  }
  &__submenu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  &__submenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #DA4132;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 0;
    z-index: 10;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  &__submenu-item {
    display: block;
    padding: 8px 16px;
    white-space: nowrap;
    font-weight: 500;
    text-decoration: none;
    transition: .5s all ease;
    color: whitesmoke;
    background-color: #DA4132;

    &:hover {
      color: whitesmoke;
      background-color: #a92b1f;
    }
  }

  &__logo {
    text-decoration: none;
    img {
      max-width: 349px;
      @media (max-width: 768px) {
        max-width: 255px;
      }
    }
  }
  &__hamburger {
    display: none;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      fill: #FFFF;
    }
    @media (max-width: 768px) {
      display: flex;
    }
  }
  &__mobile-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 110px;
    left: 0;
    width: 100%;
    background-color: #3397D2;
    padding: 16px 0;
    z-index: 10;

    @media (max-width: 768px) {
      display: flex;
      padding: 16px;
      box-shadow: none;
      gap: 8px;
    }
  }

  &__mobile-submenu {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__mobile-submenu-item {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #FFFF;
    padding: 8px 0;

    &:hover {
      color: #a92b1f;
    }
    @media (max-width: 768px) {
      padding: 0;
      font-weight: 500;
      color: #DA4132;
    }
  }
}

.box {
  flex: 1;
  display: flex;
  justify-content: center;
}

.box:first-child {
  margin-right: auto;
  justify-content: flex-start;
}

.box:last-child {
  margin-left: auto;
  align-self: center;
  justify-content: flex-end;
}

</style>
