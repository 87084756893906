<template>
  <div class="fixed-button" @click="redirectToWhatsapp">
    <i class="fab fa-whatsapp"><img src="" alt=""></i>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToWhatsapp() {
      window.location.href = "https://wa.me/6285272016810"
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  i {
    font-size: 24px;
    img {
      width: 100%;
    }
  }
}
</style>
